import React from 'react'
import {
  Flex,
  Box,
  Container,
  Link,
  Dot,
  FadeIn,
  Hero,
  Layout,
  Redirect,
} from 'components'
import locales from 'config/locales'
import heroImage from 'images/home-hero.jpg'
// Photo by Eutah Mizushima on Unsplash

export const IndexPage = ({ language }) => {
  const locale = locales[language]
  const page = locale.pages.home

  return (
    <Layout language={language} title={page.title}>
      <Hero
        backgroundImage={heroImage}
        backgroundColor="hsla(49, 42%, 100%, 1)"
        backgroundPosition={{ _: 'center 33%', sm: 'center 3%' }}
      >
        <Box>
          <FadeIn delay="0s">
            <Box
              as={'h2'}
              textShadow={'hero'}
              lineHeight={'1.6em'}
              color={'primary.white'}
              fontSize={{ _: '1.6em', md: '2em' }}
              transition={'all 0.8s ease'}
              styled={{
                '&:hover': {
                  color: 'primary.pink',
                },
              }}
            >
              {page.hero1}
              <br />
              {page.hero2}
            </Box>
          </FadeIn>
        </Box>
      </Hero>

      <Container>
        <Box
          as={'h2'}
          marginY={8}
          marginX={4}
          marginTop={14}
          fontWeight={3}
          letterSpacing={'1'}
          textAlign={'center'}
          fontFamily={'heading'}
        >
          {page.bodyTitle}
        </Box>

        <Box
          as={'p'}
          fontFamily={'body'}
          paddingX={{ _: 4, md: 12 }}
          lineHeight={'1.8em'}
          textAlign={'center'}
        >
          {page.bodyText}
        </Box>

        <Dot
          backgroundColor="neutral.1"
          marginY={{ _: 10, md: 16 }}
          align="center"
        />

        {/* TODO: add links to topic boxes */}
        <Flex flexDirection={{ _: 'column', md: 'row' }}>
          {page.topicBoxes.map(({ link, title, text, image }, idx) => (
            <Box
              as={Link}
              key={idx}
              to={link}
              width={{ _: 1, md: 1 / 2 }}
              color={'inherit'}
              marginBottom={12}
              textDecoration={'none'}
            >
              <Box
                as={'h3'}
                fontWeight={3}
                fontFamily={'heading'}
                textAlign={'center'}
                margin={'0'}
              >
                {title}
              </Box>

              <Box
                as={'p'}
                fontSize={0}
                fontWeight={0}
                fontFamily={'body'}
                lineHeight={'1.8em'}
                textAlign={'center'}
                paddingX={{ _: 6, md: 12 }}
                marginTop={4}
                marginBottom={6}
                minHeight={{ _: 'auto', md: '180px' }}
              >
                {text}
              </Box>

              <Flex
                as={'img'}
                src={image}
                alt={title}
                width={{ _: '90%', md: '420px' }}
                marginX={'auto'}
              />

              {/*<img src={image} alt={title} width="100%" />*/}
            </Box>
          ))}
        </Flex>
      </Container>
    </Layout>
  )
}

export default Redirect
